'use strict';

var cleave = require('../components/cleave');
var isMobile = !($(window).width() >= 768);

/**
 * clears the credit card form
 */
function clearCreditCardForm() {
    if ($('input[name$="_cardNumber"]').length > 0) {
        $('input[name$="_cardNumber"]').data('cleave').setRawValue('');
    }
    $('input[name$="expiry-date"]').val('');
    $('input[name$="_expirationMonth"]').val('');
    $('input[name$="_expirationYear"]').val('');
    $('input[name$="_securityCode"]').val('');
    $('input').parent().find('label').removeClass('active');
}

module.exports = {
    methods: {
        clearCreditCardForm: clearCreditCardForm
    },

    handleCreditCardNumber: function () {
        var cardNumber = $('.cardNumber');
        if (cardNumber.length > 0) {
            cleave.handleCreditCardNumber('.cardNumber', '#cardType');
        }
    },

    // checkifPageisQuickRedister: function () {
    //     if (sessionStorage.getItem('card-number') && sessionStorage.getItem('expiry-date')) {
    //         $('#cardNumber').val(sessionStorage.getItem('card-number'));
    //         $('#expiry-date').val(sessionStorage.getItem('expiry-date'));
    //         sessionStorage.setItem('validateform', true);
    //         this.handleCreditCardNumber();
    //     }
    // },

    expirationSlash: function () {
        $(document).on('keydown', '#expiry-date', function (e) {
            var $keycode = e.originalEvent.data ? e.originalEvent.data.charCodeAt(0) : e.originalEvent.which;
            // numbers (letterpad + numpad), modifiers, direction and deletion keys
            var allowedKeyCodes = [8, 46, 33, 34, 35, 36, 37, 38, 39, 40, 16];
            if ($(this).val().length !== 2 && (($keycode < 48 || $keycode > 57) && ($keycode < 96 || $keycode > 105) && allowedKeyCodes.indexOf($keycode) === -1)) {
                e.preventDefault();
            }
            // only "/" can be typed
            var allowedKeyCodesAfterMonth = [16, 17, 18, 111, 191];
            if ($(this).val().length === 2 && allowedKeyCodesAfterMonth.indexOf($keycode) === -1 && allowedKeyCodes.indexOf($keycode) === -1) {
                e.preventDefault();
            }
        });

        $(document).on('keyup', '#expiry-date', function (e) {
            var $keycode = e.originalEvent.data ? e.originalEvent.data.charCodeAt(0) : e.originalEvent.which;
            // adds "/" automatically
            if ($(this).val().length === 2 && (($keycode >= 48 && $keycode <= 57) || ($keycode >= 96 && $keycode <= 105))) {
                $(this).val($(this).val() + '/');
            }
        });

        $(document).on('blur', '#expiry-date', function () {
            var $this = $(this);
            var $thisVal = $this.val();

            var monthYearParts = $thisVal.split('/');
            if (monthYearParts.length !== 2 || monthYearParts[0].length !== 2 || monthYearParts[1].length !== 2) {
                return;
            }

            $('#expirationMonth').val(monthYearParts[0]);
            $('#expirationYear').val('20' + monthYearParts[1]);
        });
    },

    santitizeForm: function () {
        $('body').on('checkout:serializeBilling', function (e, data) {
            var serializedForm = cleave.serializeData(data.form);

            data.callback(serializedForm);
        });
    },

    selectSavedPaymentInstrument: function () {
        $(document).on('click', '.saved-payment-instrument:not(.selected-payment)', function (e) {
            e.preventDefault();

            $('.saved-payment-security-code').val('');
            $('.saved-payment-instrument').removeClass('selected-payment');
            $(this).find('#saved-payment-security-code').removeClass('d-none');
            $(this).addClass('selected-payment');
            $('.saved-payment-instrument .card-image').removeClass('checkout-hidden');
            $('.saved-payment-instrument .checker').prop('checked', false);
            $('.saved-payment-instrument.selected-payment .checker').prop('checked', true);
            $('.saved-payment-instrument .security-code-input').addClass('checkout-hidden');

            $('.overlayerCartAction .addCvv').addClass('is-disabled');
            // $('.overlayerCartAction .addCvv').text(resources.buttonAddCVV);
            if (!isMobile) {
                $('#submitPaymentCartBtn').addClass('is-disabled');
                // $(this).siblings().hide();
                // $('.saved-cards-box.js-new-card-trigger').hide();
                // $('.checkout-dummy-btn').hide();
                // $('.make-payment-button').removeClass('d-none');
                // $('.more-cards-btn').show();
                module.exports.closeNewPayment();
            } else {
                $('.selected-payment .cvc-number').on('keyup', function () {
                    var $this = $(this);
                    var $length = $this.val().length;
                    var $button = $('.overlayerCartAction .addCvv');
                    if ($length >= 3) {
                        $button.removeClass('is-disabled');
                        // $button.text(resources.buttonUseSelect);
                        $('.selectedpaymentmethod').val($(this).data('payment-id'));
                        $('.billing-nav.payment-information').attr('data-payment-method-id', $(this).data('payment-id'));
                    } else {
                        $button.addClass('is-disabled');
                        // $button.text(resources.buttonAddCVV);
                        $('.selectedpaymentmethod').val($(this).data('payment-id'));
                        $('.billing-nav.payment-information').attr('data-payment-method-id', $(this).data('payment-id'));
                    }
                });
            }
        });
    },

    showMorecards: function () {
        $(document).on('click', '.more-cards-btn', function (e) {
            e.preventDefault();
            $('.saved-cards-box.js-new-card-trigger').show();
            $('.checkout-dummy-btn').show();
            $('.saved-payment-instrument').removeClass('selected-payment').show();
            // $('.make-payment-button').addClass('d-none');
            $('#submitPaymentCartBtn').addClass('is-disabled');
            $('.credit-card-form').addClass('checkout-hidden');
            $('.checkout-dummy-btn').addClass('checkout-hidden');
            $('.payment-information').data('is-new-payment', false);
            clearCreditCardForm();
            $('.user-payment-instruments').removeClass('checkout-hidden');
            $('.checkout-dummy-btn').removeClass('checkout-hidden');
            $('.credit-card-form').addClass('checkout-hidden');
            $('.checkout-dummy-btn').show();
            $(this).hide();
        });

        $(document).on('click', '.saved-payment-instrument:not(.selected-payment)', function (e) {
            $('body').trigger('GTM:opensavedcard');
        });
    },

    addNewPaymentInstrument: function () {
        $('.js-new-card-trigger').on('click', function (e) {
            e.preventDefault();
            $('.saved-payment-security-code').val('');
            $('.saved-payment-instrument').removeClass('selected-payment');
            $('.payment-information').data('is-new-payment', true);
            clearCreditCardForm();
            $('.credit-card-form').removeClass('checkout-hidden');
            $('.checkout-dummy-btn').removeClass('checkout-hidden');
            $('.user-payment-instruments').addClass('checkout-hidden');
            $('.checkout-dummy-btn').addClass('checkout-hidden');

            if (!isMobile) {
                // $('.saved-payment-instrument').hide();
                $('.saved-cards-box.js-new-card-trigger').hide();
                $('#submitPaymentCartBtn').addClass('is-disabled');
                $('.checkout-dummy-btn').hide();
                $(this).addClass('selected-payment');
                $('.saved-payment-instrument .checker').prop('checked', false);
            }
            $('body').trigger('GTM:newcard');
        });
    },

    cancelNewPayment: function () {
        $('.cancel-new-payment').on('click', function (e) {
            e.preventDefault();
            $('.payment-information').data('is-new-payment', false);
            clearCreditCardForm();
            $('.user-payment-instruments').removeClass('checkout-hidden');
            $('.checkout-dummy-btn').removeClass('checkout-hidden');
            $('.credit-card-form').addClass('checkout-hidden');
            $('.checkout-dummy-btn').addClass('checkout-hidden');
        });

        $('.js-close-new-card').on('click', function (e) {
            e.preventDefault();
            $('.saved-payment-instrument:first-child').addClass('selected-payment');
            $('.saved-payment-instrument:first-child .checker').prop('checked', true);
            $('.saved-payment-instrument:not(:first-child) .checker').prop('checked', false);
            module.exports.closeNewPayment();
        });
    },

    closeNewPayment: function () {
        $('.user-payment-instruments').removeClass('checkout-hidden');
        $('.credit-card-form').addClass('checkout-hidden');
        $('.payment-information').data('is-new-payment', false);
        $('.saved-payment-security-code').val('');
        $('.saved-cards-box.js-new-card-trigger').show().removeClass('selected-payment');
        clearCreditCardForm();
    },

    paymentTabs: function () {
        $('.payment-options .nav-item').on('click', function (e) {
            e.preventDefault();
            var methodID = $(this).data('method-id');
            $('.payment-information').data('payment-method-id', methodID);
        });
    },

    dummyBtnHover: function () {
        $('.checkout-dummy-btn').hover(
            function () {
                $('.stored-payments').addClass('hovered');
            }, function () {
                $('.stored-payments').removeClass('hovered');
            }
        );
    },

    handlePaypalPayment: function () {
        $('.paypal-payments').on('click', function () {
            var paymentID = $(this).data('id');
            $('.selectedpaymentmethod').val(paymentID);
            var $button = $('.overlayerCartAction .addCvv');
            $button.removeClass('is-disabled');
            $('.billing-nav.payment-information').attr('data-payment-method-id', paymentID);
        });
    }
};
