/* eslint-disable no-undef */
'use strict';
var isMobile = !($(window).width() >= 768);
const helper = require('./helper');

/**
 * replace content of modal
 * @param {JSON} data JSON
 */
function minimumOrderDesktop(data) {
    var miniMumOrderAmount = parseFloat(data.minimumOrderValue.replace(/[^0-9.]+/g, ''));
    if (data.checkoutAllowed) {
        // $('.newbuttonMinOrder .mobile-button').removeClass('is-disabled');
        $('.minimumOrder-indicator').removeClass('show');
    } else if (miniMumOrderAmount > 0 ) {
        // $('.newbuttonMinOrder .mobile-button').addClass('is-disabled');
        $('.minimumOrder-indicator').addClass('show').find('p b').html(data.amountRequiredForCheckout);
        $('.minimumOrder-indicator .indiBar span').css('width', `${parseInt(data.checkoutPercentageRequired)}%`);
        // $('.item-block .item-quantity .js-plus').addClass('pulseAnim');
    }
}

/**
 * used to update shipping address on view
 * @param {*} data response
 */
function checkShipping(data) {
    if (typeof data !== 'undefined' && data) {
        minimumOrderDesktop(data);
    }

    var activeButtton = helper.getActivePaymentButton();

    if ($('.shipping-page-details.d-none').length >= 1 && $('.minimumOrder-indicator.show').length === 0) {
        $(activeButtton).prop('disabled', false);
        $(activeButtton).removeClass('is-disabled');
    } else if ($('.selfpickup-box').hasClass('checked') && $('.minimumOrder-indicator.show').length === 0) {
        $(activeButtton).prop('disabled', false);
        $(activeButtton).removeClass('is-disabled');
    } else if ($('.standard-shipping-box').hasClass('checked')) {
        if ($('.address-btn.active').length > 0 && $('.minimumOrder-indicator.show').length === 0) {
            $(activeButtton).prop('disabled', false);
            $(activeButtton).removeClass('is-disabled');
        } else {
            $(activeButtton).prop('disabled', 'disabled');
            $(activeButtton).addClass('is-disabled');
        }
    } else {
        $(activeButtton).prop('disabled', 'disabled');
        $(activeButtton).addClass('is-disabled');
    }
    // minimumOrderDesktop(data);
}

module.exports = {
    newcardPaymentValidation: function () {
        $('#dwfrm_cart').validate({
            modules: 'date',
            rules: {
                cardNumber: {
                    required: true,
                    minlength: 14,
                    regex: '^[0-9]*$'
                },
                cardOwner: {
                    required: true,
                    minlength: 2,
                    regex: '^[a-zA-Z -]*$',
                    onlySpace: '#card-holder-name'
                },
                'expiry-date': {
                    required: true,
                    dateFormat: '#expiry-date'
                },
                dwfrm_cart_creditCardFields_securityCode: {
                    required: true,
                    regex: '^[0-9]*$'
                }
            },
            messages: {
                cardNumber: {
                    required: resources.Global$fieldRequired,
                    minlength: resources.Global$cardMax
                },
                cardOwner: {
                    required: resources.Global$NameRequired,
                    minlength: resources.Global$nameMin
                },
                'expiry-date': {
                    required: resources.Global$expiryDate,
                    dateFormat: resources.Global$dateInvalid
                },
                dwfrm_cart_creditCardFields_securityCode: {
                    required: resources.Global$cvvRequired,
                    regex: resources.Global$cvvRequired
                }
            }
        });
        // module.exports.buttonPaymentEnable('#dwfrm_cart', '#dwfrm_cart #cardNumber', '#dwfrm_cart #cardOwner', '#dwfrm_cart #expiry-date', '#dwfrm_cart #securityCode', '#submitPaymentCartBtn');
        if (isMobile) {
            module.exports.buttonPaymentEnable('#dwfrm_cart', '#dwfrm_cart #cardNumber', '#dwfrm_cart #cardOwner', '#dwfrm_cart #expiry-date', '#dwfrm_cart #securityCode', '.js-continue-to-payment.new-card-button');
        } else {
            module.exports.buttonPaymentEnableDesktop();
        }
    },
    buttonPaymentEnable: function (elemForm, elemCardNumber, elemName, elemDate, elemCvv, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(.saved-payment-security-code):not(.dummy)', function () {
            if ($(elemCardNumber).val().length > 16) {
                if ((!$(elemCardNumber).hasClass('invalid')) && (
                    $(elemName).val().length >= 2 &&
                    $(elemDate).val().length === 5 &&
                    !$(elemDate).hasClass('invalid') &&
                    $(elemCvv).val().length > 2
                )) {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            }

            if ($(elemName).val().length >= 2) {
                if (
                    (!$(elemName).hasClass('invalid')
                    ) && (
                        $(elemCardNumber).val().length > 16 &&
                        !$(elemCardNumber).hasClass('invalid') &&
                        $(elemDate).val().length === 5 &&
                        !$(elemDate).hasClass('invalid') &&
                        $(elemCvv).val().length > 2
                    )) {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            }

            if ($(elemDate).val().length === 5) {
                if (
                    (!$(elemDate).hasClass('invalid') || $(elemDate).val().length > 4
                    ) && (
                        $(elemCardNumber).val().length > 16 &&
                        !$(elemCardNumber).hasClass('invalid') &&
                        $(elemName).val().length >= 2 &&
                        $(elemCvv).val().length > 2
                    )) {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            }

            if ($(elemCvv).val().length > 2) {
                if (
                    ($(this).valid() &&
                        !$(elemDate).hasClass('invalid')
                    ) && (
                        $(elemCardNumber).val().length > 16 &&
                        !$(elemCardNumber).hasClass('invalid') &&
                        $(elemName).val().length >= 2 &&
                        $(elemDate).val().length === 5 &&
                        !$(elemDate).hasClass('invalid')
                    )) {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            }
        });
    },
    buttonPaymentEnableDesktop: function (elemForm, elemCardNumber, elemName, elemDate, elemCvv, elemButton) {
        if (!isMobile) {
            $('#dwfrm_cart').on('keyup', 'input:not(:button):not(:checkbox):not(.saved-payment-security-code):not(.dummy)', function () {
                $(document).trigger('desktop:newCardValidation');
            });
        }
    },
    desktopCheckoutValidationNewCard: function () {
        $(document).on('desktop:newCardValidation', function () {
            var activeButtton = helper.getActivePaymentButton();
            var data = $('body').attr('data');
            if (typeof data !== 'undefined' && data) {
                data = JSON.parse(data);
                if (data && data.isCreditApplied && data.isFullyPaidViaCredit) {
                    $('.payment-page-detail').addClass('d-none');
                    checkShipping(data);
                } else {
                    $('.payment-page-detail').removeClass('d-none');
                    // eslint-disable-next-line no-lonely-if
                    if ((!$('#dwfrm_cart #cardNumber').hasClass('invalid') && $('#dwfrm_cart #cardNumber').val().length > 16) && (
                        (!$('#dwfrm_cart #cardOwner').hasClass('invalid') && $('#dwfrm_cart #cardOwner').val().length >= 2) &&
                        (!$('#dwfrm_cart #expiry-date').hasClass('invalid') && $('#dwfrm_cart #expiry-date').val().length === 5) &&
                        (!$('#dwfrm_cart #securityCode').hasClass('invalid') && $('#dwfrm_cart #securityCode').val().length > 2)
                    )) {
                        checkShipping(data);
                    } else {
                        $(activeButtton).prop('disabled', 'disabled');
                        $(activeButtton).addClass('is-disabled');
                        minimumOrderDesktop(data);
                    }
                }
            } else if ((!$('#dwfrm_cart #cardNumber').hasClass('invalid') && $('#dwfrm_cart #cardNumber').val().length > 16) && (
                (!$('#dwfrm_cart #cardOwner').hasClass('invalid') && $('#dwfrm_cart #cardOwner').val().length >= 2) &&
                (!$('#dwfrm_cart #expiry-date').hasClass('invalid') && $('#dwfrm_cart #expiry-date').val().length === 5) &&
                (!$('#dwfrm_cart #securityCode').hasClass('invalid') && $('#dwfrm_cart #securityCode').val().length > 2))) {
                checkShipping(data);
            } else {
                $(activeButtton).prop('disabled', 'disabled');
                $(activeButtton).addClass('is-disabled');
                // minimumOrderDesktop(data);
            }
        });
    },
    desktopCheckoutValidationSavedCard: function () {
        $(document).on('desktop:saveCardValidation', function () {
            var data = $('body').attr('data');
            var activeButtton = helper.getActivePaymentButton();

            if (typeof data !== 'undefined' && data) {
                data = JSON.parse(data);
                if (data && data.isCreditApplied && data.isFullyPaidViaCredit) {
                    $('.payment-page-detail').addClass('d-none');
                    checkShipping(data);
                } else {
                    $('.payment-page-detail').removeClass('d-none');
                    // eslint-disable-next-line no-lonely-if
                    if ($('.selected-payment .cvv').length > 0 && $('.selected-payment .cvv').val().length > 2) {
                        checkShipping(data);
                    } else {
                        $(activeButtton).prop('disabled', 'disabled');
                        $(activeButtton).addClass('is-disabled');
                        minimumOrderDesktop(data);
                    }
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if ($('.selected-payment .cvv').length > 0 && $('.selected-payment .cvv').val().length > 2) {
                    checkShipping(data);
                } else {
                    $(activeButtton).prop('disabled', 'disabled');
                    $(activeButtton).addClass('is-disabled');
                    // minimumOrderDesktop(data);
                }
            }
        });
    },
    desktopCheckoutValidationWalletPayments: function () {
        $(document).on('desktop:walletPaymentValidation', function () {
            var data = $('body').attr('data');
            if (typeof data !== 'undefined' && data) {
                data = JSON.parse(data);
                checkShipping(data);
            } else {
                checkShipping(data);
            }
        });
    },
    /**
     * trigger to validate payment
     */
    validatepaymentGlobal: function () {
        $(document).on('validated:payment', function () {
            if (isMobile) {
                var $this = $('.selected-payment .saved-payment-security-code');
                var $length = $this.val().length;
                var $button = $('.bottom-floater .addCvv');
                if ($length >= 3) {
                    $button.removeClass('is-disabled');
                } else {
                    $button.addClass('is-disabled');
                }
            } else {
                $(document).trigger('desktop:saveCardValidation');
            }
        });
    }
};
