/* eslint-disable no-constant-condition */
/* eslint-disable no-cond-assign */
'use strict';

var toastr = require('toastr');
var billingHelpers = require('./billing');
var formHelpers = require('base/checkout/formErrors');
var isMobile = !($(window).width() >= 768);

/**
 * Create the jQuery Cart Checkout Plugin.
 *
 * This jQuery plugin will be registered on the dom element in cart.isml with the
 * id of "cart-main".
 *
 * Payment info are used a bit synonymously in this code.
 *
 */
(function ($) {
    $.fn.cartcheckout = function () { // eslint-disable-line
        var plugin = this;

        //
        // Collect form data from user input
        //
        var formData = {
            // Payment
            payment: {},

            // Gift Codes
            giftCode: {}
        };

        //
        // Local member methods of the Checkout plugin
        //
        var members = {

            mobileError: function (error) {
                $('.mobilePaymentError').addClass('errorFound');
                $('.mobilePaymentError span').text(error);
                // $('.bundleOfferSections, .discountBanner, .cartNewContainer, .cart-shipping, .cart-section-item, .suggestionBox').show();
                // $('.shipping-page-details, .payment-page-detail, .cart-shipping').hide();
                // $('.content-wrapper header h1').text(resources.cartTitle);
                // $('.page-progress').removeClass('second-step').addClass('first-step');
                $('.new-card-area #cardNumber,.new-card-area #expiry-date,.new-card-area #securityCode, .cvc-number').val('');
                $('.js-continue-to-payment').addClass('is-disabled');
                // $('.new-card-area label').removeClass('active');
                // $('.new-card-area').removeClass('showModule');
                // $('.cartOverlay').removeClass('showModule');
                // $('.newCreditCard-actions .js-continue-to-shipping-mobile').addClass('is-disabled');
                // $('.make-payment-button button').addClass('is-disabled');
                // $('.js-add-new-card, .card-item').show();
                // $('.use-this').removeClass('closed');

                // $('.use-this').text(resources.paymentUseThis);
                // $('.mobile-CVV').removeClass('active');
                // $('.mobile-CVV').find('.dummy').val('');
                // $('.mobile-CVV').find('.cvc-number').val('');
                // $('.payment-ipoints').show();
                // $('.section-title').show();
                // $('.tooltip-for-ipoints').removeClass('checked');
            },

            /**
             * Handles the payment
             */
            handlePayment: function () {
                //
                // Submit the Billing Address Form
                //

                formHelpers.clearPreviousErrors('.payment-form');
                localStorage.removeItem('cartError');
                localStorage.removeItem('cartErrorServer');
                var activeTabId = $('.tab-pane.active').attr('id');
                var paymentInfoSelector = '#dwfrm_cart .' + activeTabId + ' .payment-form-fields';
                var paymentInfoForm = $(paymentInfoSelector).serialize();
                $('body').trigger('checkout:serializeBilling', {
                    form: $(paymentInfoSelector),
                    data: paymentInfoForm,
                    callback: function (data) {
                        if (data) {
                            paymentInfoForm = data;
                        }
                    }
                });

                var paymentForm = paymentInfoForm;

                if ($('.saved-payment-instrument').length > 0 && $('.saved-payment-instrument.selected-payment').length > 0) {
                    // if payment method is credit card
                    if ($('.credit_card-content.active').length > 0) {
                        var cvvCode = $('.saved-payment-instrument.' +
                            'selected-payment .saved-payment-security-code').val();
                        var $savedPaymentInstrument = $('.saved-payment-instrument' +
                            '.selected-payment'
                        );
                        paymentForm += '&storedPaymentUUID=' + $savedPaymentInstrument.data('uuid');
                        paymentForm += '&securityCode=' + cvvCode;
                    }
                }

                // disable the next:Place Order button here
                $('body').trigger('checkout:disableButton', '.make-payment-button button');
                $.spinner().start();
                // $('body').addClass('idealz-loading');
                $.ajax({
                    url: $('#dwfrm_cart').data('submit-payment-action'),
                    method: 'POST',
                    data: paymentForm,
                    success: function (data) {
                        // enable the next:Place Order button here
                        $('body').trigger('checkout:enableButton', '.make-payment-button button');

                        // look for field validation errors
                        if (data.error) {
                            if (data.fieldErrors.length) {
                                data.fieldErrors.forEach(function (error) {
                                    if (Object.keys(error).length) {
                                        var $erros = '';
                                        $.each(error, function (key, value) {
                                            $erros = $erros.concat(value + '. ');
                                            // eslint-disable-next-line no-param-reassign
                                            if (key === 'dwfrm_cart_creditCardFields_expirationMonth' || key === 'dwfrm_cart_creditCardFields_expirationYear') {
                                                $('#expiry-date').addClass('invalid').removeClass('valid');
                                                $('#expiry-date').closest('.input-field').find('label').addClass('invalid');
                                                $('#expiry-date').closest('.input-field').find('label').removeClass('valid');
                                                $('#expiry-date').closest('.input-field').find('label').text(value);

                                            } else {
                                                $('*[name=' + key + ']').addClass('invalid').removeClass('valid');
                                                $('*[name=' + key + ']').closest('.input-field').find('label').addClass('invalid');
                                                $('*[name=' + key + ']').closest('.input-field').find('label').removeClass('valid');
                                                $('*[name=' + key + ']').closest('.input-field').find('label').text(value);
                                            }
                                        });
                                        members.mobileError($erros);
                                        // toastr.error($erros);
                                    }
                                });
                                $('#submitPaymentCartBtn').prop('disabled', true);
                                $('#submitPaymentCartBtn').addClass('is-disabled');
                                $.spinner().stop();
                            }

                            if (data.serverErrors.length) {
                                data.serverErrors.forEach(function (error) {
                                    if (isMobile) {
                                        // eslint-disable-next-line no-alert
                                        alert(error);
                                    } else {
                                        toastr.remove();
                                        toastr.error(error);
                                    }
                                });
                                $('#submitPaymentCartBtn').prop('disabled', true);
                                $('#submitPaymentCartBtn').addClass('is-disabled');
                                $.spinner().stop();
                            }

                            if (data.cartError) {
                                window.location.href = data.redirectUrl;
                            }

                            // $('body').removeClass('idealz-loading');

                        } else {

                            members.authorizePayment();
                        }
                    },
                    error: function (err) {
                        // enable the next:Place Order button here
                        $('body').trigger('checkout:enableButton', '.make-payment-button button');
                        if (err.responseJSON && err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                            return;
                        }

                        $('#submitPaymentCartBtn').prop('disabled', true);
                        $('#submitPaymentCartBtn').addClass('is-disabled');
                        $.spinner().stop();
                    }
                });
            },

            handlePaymentStripe: function () {
                //
                // Submit the Billing Address Form
                //

                formHelpers.clearPreviousErrors('.payment-form');
                localStorage.removeItem('cartError');
                localStorage.removeItem('cartErrorServer');
                var activeTabId = $('.tab-pane.active').attr('id');
                var paymentInfoSelector = '#dwfrm_cart .' + activeTabId + ' .payment-form-fields';
                var paymentInfoForm = $(paymentInfoSelector).serialize();
                $('body').trigger('checkout:serializeBilling', {
                    form: $(paymentInfoSelector),
                    data: paymentInfoForm,
                    callback: function (data) {
                        if (data) {
                            paymentInfoForm = data;
                        }
                    }
                });

                var paymentForm = paymentInfoForm;

                if ($('.saved-payment-instrument').length > 0 && $('.saved-payment-instrument.selected-payment').length > 0) {
                    // if payment method is credit card
                    if ($('.credit_card-content.active').length > 0) {
                        var cvvCode = $('.saved-payment-instrument.' +
                            'selected-payment .saved-payment-security-code').val();
                        var $savedPaymentInstrument = $('.saved-payment-instrument' +
                            '.selected-payment'
                        );
                        paymentForm += '&storedPaymentUUID=' + $savedPaymentInstrument.data('uuid');
                        paymentForm += '&securityCode=' + cvvCode;
                    }
                }

                // disable the next:Place Order button here
                $('body').trigger('checkout:disableButton', '.make-payment-button button');
                $.spinner().start();
                // $('body').addClass('idealz-loading');
                $.ajax({
                    url: $('#dwfrm_cart').data('submit-payment-action'),
                    method: 'POST',
                    data: paymentForm,
                    success: function (data) {
                        // enable the next:Place Order button here
                        $('body').trigger('checkout:enableButton', '.make-payment-button button');

                        // look for field validation errors
                        if (data.error) {
                            if (data.fieldErrors.length) {
                                data.fieldErrors.forEach(function (error) {
                                    if (Object.keys(error).length) {
                                        var $erros = '';
                                        $.each(error, function (key, value) {
                                            $erros = $erros.concat(value + '. ');
                                            // eslint-disable-next-line no-param-reassign
                                            if (key === 'dwfrm_cart_creditCardFields_expirationMonth' || key === 'dwfrm_cart_creditCardFields_expirationYear') {
                                                $('#expiry-date').addClass('invalid').removeClass('valid');
                                                $('#expiry-date').closest('.input-field').find('label').addClass('invalid');
                                                $('#expiry-date').closest('.input-field').find('label').removeClass('valid');
                                                $('#expiry-date').closest('.input-field').find('label').text(value);
                                            } else {
                                                $('*[name=' + key + ']').addClass('invalid').removeClass('valid');
                                                $('*[name=' + key + ']').closest('.input-field').find('label').addClass('invalid');
                                                $('*[name=' + key + ']').closest('.input-field').find('label').removeClass('valid');
                                                $('*[name=' + key + ']').closest('.input-field').find('label').text(value);
                                            }
                                        });
                                        members.mobileError($erros);
                                        // toastr.error($erros);
                                    }
                                });
                                $('#submitPaymentCartBtn').prop('disabled', true);
                                $('#submitPaymentCartBtn').addClass('is-disabled');
                                $.spinner().stop();
                            }

                            if (data.serverErrors.length) {
                                data.serverErrors.forEach(function (error) {
                                    if (isMobile) {
                                        // eslint-disable-next-line no-alert
                                        alert(error);
                                    } else {
                                        toastr.remove();
                                        toastr.error(error);
                                    }
                                });
                                $('#submitPaymentCartBtn').prop('disabled', true);
                                $('#submitPaymentCartBtn').addClass('is-disabled');
                                $.spinner().stop();
                            }

                            if (data.cartError) {
                                window.location.href = data.redirectUrl;
                            }

                            // $('body').removeClass('idealz-loading');
                        } else {
                            $('button.place-order').trigger('click');
                        }
                    },
                    error: function (err) {
                        // enable the next:Place Order button here
                        $('body').trigger('checkout:enableButton', '.make-payment-button button');
                        if (err.responseJSON && err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                            return;
                        }

                        $('#submitPaymentCartBtn').prop('disabled', true);
                        $('#submitPaymentCartBtn').addClass('is-disabled');
                        $.spinner().stop();
                    }
                });
            },

            /**
             * Authorizes the payment
             */
            authorizePayment: function () {
                // disable the placeOrder button here
                $('body').trigger('checkout:disableButton', '.make-payment-button button');
                var cvvCode = $('.saved-payment-instrument.' +
                    'selected-payment .saved-payment-security-code').val();

                $.ajax({
                    url: $('#dwfrm_cart').data('place-order-action'),
                    method: 'POST',
                    data: {
                        securityCode: cvvCode
                    },
                    success: function (data) {
                        // enable the placeOrder button here
                        $('body').trigger('checkout:enableButton', '.make-payment-button button');


                        if (data.error) {
                            if (data.cartError) {
                                window.location.href = data.redirectUrl;
                                return;
                            }
                            if (data.quantityUpdateData) {
                                for (var i = 0; i < data.quantityUpdateData.length; i++) {
                                    if (isMobile) {
                                        members.mobileError(data.quantityUpdateData[i].message);
                                    } else {
                                        toastr.remove();
                                        toastr.error(data.quantityUpdateData[i].message);
                                    }
                                }
                                setTimeout(function () {
                                    window.location.reload();
                                }, 3000);
                                $.spinner().stop();
                            } else {
                                // eslint-disable-next-line no-lonely-if
                                if (isMobile) {
                                    members.mobileError(data.errorMessage);
                                } else {
                                    toastr.remove();
                                    toastr.error(data.errorMessage);
                                }
                                $.spinner().stop();
                            }
                            $(document).trigger('checkout:paymentError');
                            $(document).trigger('GTM:checkout:paymentError', data);
                            // $('body').removeClass('idealz-loading');
                        } else {
                            $(document).trigger('checkout:paymentSuccess');
                            $(document).trigger('GTM:checkout:paymentSuccess');
                            if (data.redirect) {
                                window.location.href = data.redirectUrl;
                                return;
                            }
                            var continueUrl = data.continueUrl;
                            var urlParams = {
                                ID: data.orderID,
                                token: data.orderToken
                            };

                            continueUrl += (continueUrl.indexOf('?') !== -1 ? '&' : '?') +
                                Object.keys(urlParams).map(function (key) {
                                    return key + '=' + encodeURIComponent(urlParams[key]);
                                }).join('&');

                            window.location.href = continueUrl;

                            // $.spinner().stop();
                        }
                    },
                    error: function (error) {
                        // enable the placeOrder button here
                        $('body').trigger('checkout:enableButton', $('.make-payment-button button'));
                        $.spinner().stop();
                        $(document).trigger('checkout:paymentError');
                        $(document).trigger('GTM:checkout:paymentError', error);
                        // $('body').removeClass('idealz-loading');
                    }
                });
            },

            /**
             * Initialize the checkout stage.
             *
             * TODO: update this to allow stage to be set from server?
             */
            initialize: function () {
                //
                // Handle Payment option selection
                //
                $('input[name$="paymentMethod"]', plugin).on('change', function () {
                    $('.credit-card-form').toggle($(this).val() === 'CREDIT_CARD');
                });
                //
                // Handle Make Payment button click
                //

                $(plugin).on('click', '.make-payment-button button', function () {
                    members.handlePayment();
                });
                $(plugin).on('click', '.js-submit-stripe-payment', function () {
                    members.handlePaymentStripe();
                });

                $(plugin).on('click', '.js-continue-to-payment', function () {
                    members.handlePayment();
                });
                //
                // Set the form data
                //
                plugin.data('formData', formData);
            }
        };

        //
        // Initialize the checkout
        //
        members.initialize();

        return this;
    };
}(jQuery));


var exports = {
    initialize: function () {
        $('.cart-page').cartcheckout();
    },

    checkIfError: function () {
        var cartError = localStorage.getItem('cartError');
        var cartErrorServer = localStorage.getItem('cartErrorServer');

        if (cartError && cartError.length > 0) {
            alert(cartError);
            localStorage.removeItem('cartError');
        } else if (cartErrorServer && cartErrorServer.length > 0) {
            alert(cartErrorServer);
            localStorage.removeItem('cartErrorServer');
        } else {
            return;
        }
    },

    disableButton: function () {
        $('body').on('checkout:disableButton', function (e, button) {
            $(button).prop('disabled', true);
        });
    },

    enableButton: function () {
        $('body').on('checkout:enableButton', function (e, button) {
            $(button).prop('disabled', false);
        });
    }
};

[billingHelpers].forEach(function (library) {
    Object.keys(library).forEach(function (item) {
        if (typeof library[item] === 'object') {
            exports[item] = $.extend({}, exports[item], library[item]);
        } else {
            exports[item] = library[item];
        }
    });
});


module.exports = exports;
