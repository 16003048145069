/* eslint-disable no-undef */
'use strict';
var isMobile = !($(window).width() >= 768);
/**
 * Changes donation labels color according to the switch value
 * @param {jQuery} $switch given switch
 * @param {jQuery} $label donation label
 */
function handleLabelColor($switch, $label) {
    if ($switch.prop('checked')) {
        $label.addClass('checked');
        $label.removeClass('not-checked');
    } else {
        $label.addClass('not-checked');
        $label.removeClass('checked');
    }
}

/**
 * Shows/Hides Free ticket icons
 * @param {boolean} donated if donated
 */
function handleFreeTicketIcons(donated) {
    var mobileFreeTickets = $('.campaign-ticket-count:not(".non-donateable")');
    var desktopFreeTickets = $('.item-block:not(".donation-with-points") .ticket-number:not(".non-donateable")');
    var desktopFreeTicketsPrint = desktopFreeTickets.closest('.js-free-tickets-icon');

    if (mobileFreeTickets.length) {
        if (donated) {
            mobileFreeTickets.each(function () {
                var $this = $(this);
                var ticketCount = $this.data('ticket-count');
                if (ticketCount !== 1) {
                    $this.find('.number_tickets .numberoftickets').html(ticketCount);
                } else {
                    $this.find('.number_tickets .numberoftickets').html((ticketCount + 1));
                }
            });
        } else {
            mobileFreeTickets.each(function () {
                var $this = $(this);
                var ticketCount = $this.data('ticket-count');
                if (ticketCount !== 1) {
                    $this.find('.number_tickets .numberoftickets').html((ticketCount - 1));
                }
            });
        }
    } else {
        // eslint-disable-next-line no-lonely-if
        if (donated) {
            desktopFreeTickets.each(function () {
                var $this = $(this);
                var ticketCount = $this.data('ticket-count');
                if (ticketCount !== 1) {
                    if (ticketCount === 2) {
                        // $this.closest('.earlybird-interaction .js-free-tickets-icon').removeClass('d-none');
                        $this.html(ticketCount + ' ' + resources.tickets);
                    } else {
                        $this.html(ticketCount + ' ' + resources.tickets);

                    }
                }
            });
            // freeTicketIcons.removeClass('d-none');
        } else {
            desktopFreeTickets.each(function () {
                var $this = $(this);
                var ticketCount = $this.data('ticket-count');
                if (ticketCount !== 1) {
                    if (ticketCount === 2) {
                        // $this.closest('.earlybird-interaction .js-free-tickets-icon').addClass('d-none');
                        $this.html((ticketCount - 1) + ' ' + resources.ticket);
                    } else {
                        $this.html((ticketCount - 1) + ' ' + resources.tickets);
                    }
                }
            });
        }
    }
}

/**
 * Updates product and ticket quantities
 * @param {jQuery} $donateContext donation section
 * @param {Object} basket basket model returned from ajax
 */
function handleTicketQuantity($donateContext, basket) {
    var ticketQuantity = $('.js-ticket-quantity', $donateContext);
    ticketQuantity.html(basket.ticketQuantity);

    var totalQuantity = $('.js-total-quantity', $donateContext);
    totalQuantity.html(basket.totalQuantity);
}

/**
 * Updates early bird ticket amount
 * @param {Object} basket basket model returned from ajax
 */
function handleEarlyBirdTicketsLabel(basket) {
    for (var i = 0; i < basket.items.length; i++) {
        var item = basket.items[i];
        if (item.isEarlyBird && (item.quantity > item.earlyBirdTickets)) {
            $('.js-early-bird-' + item.UUID).html(item.earlyBirdLabel);
        } else {
            $('.js-early-bird-' + item.UUID).html(resources.perUnitDonated);
        }
    }
}

/**
 * updates non donated message
 * @param {*} basket 
 */
function handleNonDonateMessage(basket) {
    if (basket.haveNonDonatable && !basket.haveDonatable) {

        if ($('.js-donate-label').length > 0) {
            $('.js-donate-label').parent('.cart-stats').hide();
        }

        if ($('.ask-to-donate').length > 0) {
            $('.ask-to-donate').hide();
        }

    }
}

$('.js-donate-switch').on('change', function () {
    var $switch = $(this);
    var $donateContext = $switch.closest('.js-donate');
    var $label = $donateContext.find('.js-donate-label');

    var url = $donateContext.data('donate-url');

    $.spinner().start();
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: {
            donated: $switch.prop('checked')
        },
        success: function (basket) {
            handleLabelColor($switch, $label);
            handleFreeTicketIcons($switch.prop('checked'));
            handleTicketQuantity($donateContext, basket);
            $.spinner().stop();

        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                $.spinner().stop();
            }
        }
    });
});

$('.yes-dontate-btn').on('click', function () {
    var $switch = $(this);
    var $donateContext = $switch.closest('.js-donate');
    var cartsummary = $('.cart-summary');
    var url = $donateContext.data('donate-url');
    $.spinner().start();
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: {
            donated: true
        },
        success: function (basket) {
            // handleLabelColor($switch, $label);
            $donateContext.removeClass('dont-donate').addClass('yes-donate');
            handleFreeTicketIcons(true);
            handleTicketQuantity(isMobile ? cartsummary : $donateContext, basket);
            $('.donate-additional').text(resources.yesDonate);
            $.spinner().stop();
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                $.spinner().stop();
            }
        }
    });
});

$('body').on('cart:update', function (e, response) {
    var cartsummary = $('.cart-summary');
    handleTicketQuantity(cartsummary, response);
    handleEarlyBirdTicketsLabel(response);
    handleNonDonateMessage(response);
});

