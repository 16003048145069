var isMobile = !($(window).width() >= 768);

module.exports = {
    initValidate: function () {
        $('#home-add-address-form').validate();
        $('#work-add-address-form').validate();
        $('#other-add-address-form').validate();
    },

    quickRegisterAddAddress: function () {
        $('#home-add-address-form #suite').rules('add', {
            required: true,
            onlySpace: '#home-add-address-form #suite',
            minlength: 2,
            messages: {
                required: resources.Global$fieldRequired,
                minlength: resources.Global$fieldRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#home-add-address-form #address2').rules('add', {
            required: true,
            onlySpace: '#home-add-address-form #address2',
            minlength: 2,
            messages: {
                required: resources.Global$fieldRequired,
                minlength: resources.Global$fieldRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#home-add-address-form #address1').rules('add', {
            required: true,
            onlySpace: '#home-add-address-form #address1',
            minlength: 2,
            messages: {
                required: resources.Global$fieldRequired,
                minlength: resources.Global$fieldRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#home-add-address-form #cityShipment').rules('add', {
            required: true,
            messages: {
                required: resources.Global$fieldRequired
            }
        });
    },

    quickRegisterAddAddressWork: function () {
        $('#work-add-address-form #suite').rules('add', {
            required: true,
            onlySpace: '#work-add-address-form #suite',
            minlength: 2,
            messages: {
                required: resources.Global$fieldRequired,
                minlength: resources.Global$fieldRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#work-add-address-form #address2').rules('add', {
            required: true,
            onlySpace: '#work-add-address-form #address2',
            minlength: 2,
            messages: {
                required: resources.Global$fieldRequired,
                minlength: resources.Global$fieldRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#work-add-address-form #address1').rules('add', {
            required: true,
            onlySpace: '#work-add-address-form #address1',
            minlength: 2,
            messages: {
                required: resources.Global$fieldRequired,
                minlength: resources.Global$fieldRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#work-add-address-form #cityShipment').rules('add', {
            required: true,
            messages: {
                required: resources.Global$fieldRequired
            }
        });
    },

    quickRegisterAddAddressOther: function () {
        $('#other-add-address-form #suite').rules('add', {
            required: true,
            onlySpace: '#other-add-address-form #suite',
            minlength: 2,
            messages: {
                required: resources.Global$fieldRequired,
                minlength: resources.Global$fieldRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#work-add-address-form #address2').rules('add', {
            required: true,
            onlySpace: '#other-add-address-form #address2',
            minlength: 2,
            messages: {
                required: resources.Global$fieldRequired,
                minlength: resources.Global$fieldRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#other-add-address-form #address1').rules('add', {
            required: true,
            onlySpace: '#other-add-address-form #address1',
            minlength: 2,
            messages: {
                required: resources.Global$fieldRequired,
                minlength: resources.Global$fieldRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#other-add-address-form #cityShipment').rules('add', {
            required: true,
            messages: {
                required: resources.Global$fieldRequired
            }
        });
    },

    quickRegisterShippmentEnable: function () {
        $('#home-add-address-form input:not(hidden)').on('blur keyup', function () {
            var $allInput = $('#home-add-address-form input:not(:hidden)');
            var $allSelect = $('#home-add-address-form .custom-select:not(.is-disabled)');
            var $isValidated;
            $(this).valid();
            $allInput.each(function () {
                var $eachInput = $(this);
                var $checkEmpty = $eachInput.val();
                var $checkParent = $eachInput.closest('.input-field');

                if (!$checkParent.hasClass('invalid') && $checkEmpty) {
                    $isValidated = true;
                } else {
                    $isValidated = false;
                    return false;
                }
                return $isValidated;
            });

            if ($isValidated) {
                $allSelect.each(function () {
                    var $eachSelect = $(this).find('select');
                    var $checkEmpty = $eachSelect.children('option:selected').val();

                    if ($checkEmpty !== '0' && $checkEmpty !== '') {
                        $isValidated = true;
                    } else {
                        $isValidated = false;
                        return false;
                    }
                    return $isValidated;
                });
            }

            if ($isValidated) {
                if (isMobile) {
                    $('.js-proceed-payment').removeClass('is-disabled');
                }
                $('#home-add-address-form .mobile-button, #home-add-address-form button.global-btn').prop('disabled', false);
                $('#home-add-address-form .mobile-button, #home-add-address-form button.global-btn').removeClass('is-disabled');
            } else {
                if (isMobile) {
                    $('.js-proceed-payment').addClass('is-disabled');
                }
                $('#home-add-address-form .mobile-button, #home-add-address-form button.global-btn').prop('disabled', true);
                $('#home-add-address-form .mobile-button, #home-add-address-form button.global-btn').addClass('is-disabled');
            }
        });

        $('#home-add-address-form .custom-select:not(.is-disabled) select').on('change', function () {
            var $allInput = $('#home-add-address-form input:not(:hidden)');
            var $allSelect = $('#home-add-address-form .custom-select:not(.is-disabled)');
            var $isValidated;

            $allInput.each(function () {
                var $eachInput = $(this);
                var $checkEmpty = $eachInput.val();
                var $checkParent = $eachInput.closest('.input-field');

                if (!$checkParent.hasClass('invalid') && $checkEmpty.length > 1) {
                    $isValidated = true;
                } else {
                    $isValidated = false;
                    return false;
                }
                return $isValidated;
            });


            if ($isValidated) {
                $(this).valid();
                $allSelect.each(function () {
                    var $eachSelect = $(this).find('select');
                    var $checkEmpty = $eachSelect.children('option:selected').val();

                    if ($checkEmpty !== '0' && $checkEmpty !== '') {
                        $isValidated = true;
                    } else {
                        $isValidated = false;
                        return false;
                    }
                    return $isValidated;
                });
            }


            if ($isValidated) {
                if (isMobile) {
                    $('.js-proceed-payment').prop('disabled', false);
                    $('.js-proceed-payment').removeClass('is-disabled');
                }
                $('#home-add-address-form .mobile-button, #home-add-address-form button.global-btn').prop('disabled', false);
                $('#home-add-address-form .mobile-button, #home-add-address-form button.global-btn').removeClass('is-disabled');
            } else {
                if (isMobile) {
                    $('.js-proceed-payment').prop('disabled', true);
                    $('.js-proceed-payment').addClass('is-disabled');
                }
                $('#home-add-address-form .mobile-button, #home-add-address-form button.global-btn').prop('disabled', true);
                $('#home-add-address-form .mobile-button, #home-add-address-form button.global-btn').addClass('is-disabled');
            }
        });
    },

    quickRegisterShippmentEnableWork: function () {
        $('#work-add-address-form input:not(hidden)').on('blur keyup', function () {
            var $allInput = $('#work-add-address-form input:not(:hidden)');
            var $allSelect = $('#work-add-address-form .custom-select:not(.is-disabled)');
            var $isValidated;
            $(this).valid();
            $allInput.each(function () {
                var $eachInput = $(this);
                var $checkEmpty = $eachInput.val();
                var $checkParent = $eachInput.closest('.input-field');

                if (!$checkParent.hasClass('invalid') && $checkEmpty) {
                    $isValidated = true;
                } else {
                    $isValidated = false;
                    return false;
                }
                return $isValidated;
            });

            if ($isValidated) {
                $allSelect.each(function () {
                    var $eachSelect = $(this).find('select');
                    var $checkEmpty = $eachSelect.children('option:selected').val();

                    if ($checkEmpty !== '0' && $checkEmpty !== '') {
                        $isValidated = true;
                    } else {
                        $isValidated = false;
                        return false;
                    }
                    return $isValidated;
                });
            }

            if ($isValidated) {
                if (isMobile) {
                    $('.js-proceed-payment').removeClass('is-disabled');
                }
                $('#work-add-address-form .mobile-button, #work-add-address-form button.global-btn').prop('disabled', false);
                $('#work-add-address-form .mobile-button, #work-add-address-form button.global-btn').removeClass('is-disabled');
            } else {
                if (isMobile) {
                    $('.js-proceed-payment').addClass('is-disabled');
                }
                $('#work-add-address-form .mobile-button, #work-add-address-form button.global-btn').prop('disabled', true);
                $('#work-add-address-form .mobile-button, #work-add-address-form button.global-btn').addClass('is-disabled');
            }
        });

        $('#work-add-address-form .custom-select:not(.is-disabled) select').on('change', function () {
            var $allInput = $('#work-add-address-form input:not(:hidden)');
            var $allSelect = $('#work-add-address-form .custom-select:not(.is-disabled)');
            var $isValidated;

            $allInput.each(function () {
                var $eachInput = $(this);
                var $checkEmpty = $eachInput.val();
                var $checkParent = $eachInput.closest('.input-field');

                if (!$checkParent.hasClass('invalid') && $checkEmpty.length > 1) {
                    $isValidated = true;
                } else {
                    $isValidated = false;
                    return false;
                }
                return $isValidated;
            });


            if ($isValidated) {
                $(this).valid();
                $allSelect.each(function () {
                    var $eachSelect = $(this).find('select');
                    var $checkEmpty = $eachSelect.children('option:selected').val();

                    if ($checkEmpty !== '0' && $checkEmpty !== '') {
                        $isValidated = true;
                    } else {
                        $isValidated = false;
                        return false;
                    }
                    return $isValidated;
                });
            }


            if ($isValidated) {
                if (isMobile) {
                    $('.js-proceed-payment').removeClass('is-disabled');
                }
                $('#work-add-address-form .mobile-button,#work-add-address-form button.global-btn').prop('disabled', false);
                $('#work-add-address-form .mobile-button,#work-add-address-form button.global-btn').removeClass('is-disabled');
            } else {
                if (isMobile) {
                    $('.js-proceed-payment').addClass('is-disabled');
                }
                $('#work-add-address-form .mobile-button,#work-add-address-form button.global-btn').prop('disabled', true);
                $('#work-add-address-form .mobile-button,#work-add-address-form button.global-btn').addClass('is-disabled');
            }
        });
    },

    quickRegisterShippmentEnableOther: function () {
        $('#other-add-address-form input:not(hidden)').on('blur keyup', function () {
            var $allInput = $('#other-add-address-form input:not(:hidden)');
            var $allSelect = $('#other-add-address-form .custom-select:not(.is-disabled)');
            var $isValidated;
            $(this).valid();
            $allInput.each(function () {
                var $eachInput = $(this);
                var $checkEmpty = $eachInput.val();
                var $checkParent = $eachInput.closest('.input-field');

                if (!$checkParent.hasClass('invalid') && $checkEmpty) {
                    $isValidated = true;
                } else {
                    $isValidated = false;
                    return false;
                }
                return $isValidated;
            });

            if ($isValidated) {
                $allSelect.each(function () {
                    var $eachSelect = $(this).find('select');
                    var $checkEmpty = $eachSelect.children('option:selected').val();

                    if ($checkEmpty !== '0' && $checkEmpty !== '') {
                        $isValidated = true;
                    } else {
                        $isValidated = false;
                        return false;
                    }
                    return $isValidated;
                });
            }

            if ($isValidated) {
                if (isMobile) {
                    $('.js-proceed-payment').prop('disabled', false);
                    $('.js-proceed-payment').removeClass('is-disabled');
                }
                $('#other-add-address-form .mobile-button,#other-add-address-form button.global-btn').prop('disabled', false);
                $('#other-add-address-form .mobile-button,#other-add-address-form button.global-btn').removeClass('is-disabled');
            } else {
                if (isMobile) {
                    $('.js-proceed-payment').prop('disabled', true);
                    $('.js-proceed-payment').addClass('is-disabled');
                }
                $('.mobile-button,button.global-btn').prop('disabled', true);
                $('.mobile-button,button.global-btn').addClass('is-disabled');
            }
        });

        $('#other-add-address-form .custom-select:not(.is-disabled) select').on('change', function () {
            var $allInput = $('#other-add-address-form input:not(:hidden)');
            var $allSelect = $('#other-add-address-form .custom-select:not(.is-disabled)');
            var $isValidated;

            $allInput.each(function () {
                var $eachInput = $(this);
                var $checkEmpty = $eachInput.val();
                var $checkParent = $eachInput.closest('.input-field');

                if (!$checkParent.hasClass('invalid') && $checkEmpty.length > 1) {
                    $isValidated = true;
                } else {
                    $isValidated = false;
                    return false;
                }
                return $isValidated;
            });


            if ($isValidated) {
                $(this).valid();
                $allSelect.each(function () {
                    var $eachSelect = $(this).find('select');
                    var $checkEmpty = $eachSelect.children('option:selected').val();

                    if ($checkEmpty !== '0' && $checkEmpty !== '') {
                        $isValidated = true;
                    } else {
                        $isValidated = false;
                        return false;
                    }
                    return $isValidated;
                });
            }


            if ($isValidated) {
                if (isMobile) {
                    $('.js-proceed-payment').prop('disabled', false);
                    $('.js-proceed-payment').removeClass('is-disabled');
                }
                $('#other-add-address-form .mobile-button,#other-add-address-form button.global-btn').prop('disabled', false);
                $('#other-add-address-form .mobile-button,#other-add-address-form button.global-btn').removeClass('is-disabled');
            } else {
                if (isMobile) {
                    $('.js-proceed-payment').prop('disabled', true);
                    $('.js-proceed-payment').addClass('is-disabled');
                }
                $('.js-proceed-payment,#other-add-address-form .mobile-button,#other-add-address-form button.global-btn').prop('disabled', true);
                $('.js-proceed-payment,#other-add-address-form .mobile-button,#other-add-address-form button.global-btn').addClass('is-disabled');
            }
        });
    }
};
