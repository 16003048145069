/* eslint-disable no-undef */
'use strict';
var isMobile = !($(window).width() >= 768);
var toastr = require('../components/toastr');
const helper = require('./helper');

var $dotLoader = '<div class="dotspinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
var $dotLoaderGray = '<div class="grayDotSpinner gray"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
var $btnText = $('.js-proceed-payment').data('value');

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    toastr(message);
}

/**
 * function used to reset the payment options
 */
function resetPaymentOptions(data) {
    if (!isMobile) {
        $('.js-payment-section-buttons').addClass('d-none');
        $('.js-credit-card').find('.active').removeClass('active').addClass('d-none');
        $('.js-credit-card').find('.selected').removeClass('selected');
    }

    if ('order' in data && data.order) {
        data = data.order;
    }

    if (typeof data !== 'undefined' && data.isFullyPaidViaCredit) {
        $('.js-payment-section-buttons').removeClass('d-none');
        $('.js-payment-section-buttons .desktop-button').addClass('d-none');
        $('.js-payment-section-buttons .desktop-button').addClass('d-none');
        $('.js-payment-section-buttons .desktop-button.credits-pay-buton').removeClass('d-none').removeClass('is-disabled');
        if (isMobile) {
            if (!$('#cart-area-page').is('visible')) {
                $('.js-cart-page-buttons').addClass('d-none');
                $('.js-payment-section-buttons').removeClass('d-none');
            }
            var classToActivated = '.credit-content';
            $('.payment-card-block .tab-pane').removeClass('active');
            $(classToActivated).addClass('active');
            $('.js-select-payment-box-mobile').removeClass('selected');
            $('.js-select-payment-box-mobile-by-card').removeClass('selected');
            $('.card-payment-area').removeClass('show');
            $('.payment-subtitle, .payment-card-block').hide();
        } else {
            var classToActivated = '.credit-content';
            $('.payment-card-block .tab-pane').removeClass('active').addClass('d-none');
            $('.payment-form .tab-pane').removeClass('active');
            $(classToActivated).addClass('active').removeClass('d-none');
            $('.js-select-payment-box-desktop').removeClass('selected');
            $('.js-select-payment-box-desktop-by-card').removeClass('selected');
            $('.js-payment-section-buttons .desktop-button').addClass('d-none');
            $('.payment-page-detail').addClass('d-none');
            $('.js-payment-section-buttons .credits-pay-buton').removeClass('d-none');
            $('.js-payment-section-buttons').removeClass('d-none');
            $(document).trigger('desktop:walletPaymentValidation');
        }
    }
}

/**
 * Updates product and ticket quantities
 * @param {jQuery} elemForm donation section
 * @param {Object} elemCardNumber basket model returned from ajax
 * @param {string} elemName basket basket model returned from ajax
 * @param {string} elemDate basket basket model returned from ajax
 * @param {string} elemCvv basket basket model returned from ajax
 * @param {string} elemButton basket basket model returned from ajax
 */
function buttonPaymentEnable(elemForm, elemCardNumber, elemName, elemDate, elemCvv, elemButton) {

    if ($(elemCardNumber).val().length > 16) {
        if (($(elemCardNumber).hasClass('invalid')) && (
            $(elemName).val().length >= 2 &&
            $(elemDate).val().length === 5 &&
            !$(elemDate).hasClass('invalid') &&
            $(elemCvv).val().length > 2
        )) {
            $(elemButton).prop('disabled', false);
            $(elemButton).removeClass('is-disabled');
        } else {
            $(elemButton).prop('disabled', 'disabled');
            $(elemButton).addClass('is-disabled');
        }
    }

    if ($(elemName).val().length >= 2) {
        if (
            (!$(elemName).hasClass('invalid')
            ) && (
                $(elemCardNumber).val().length > 16 &&
                !$(elemCardNumber).hasClass('invalid') &&
                $(elemDate).val().length === 5 &&
                !$(elemDate).hasClass('invalid') &&
                $(elemCvv).val().length > 2
            )) {
            $(elemButton).prop('disabled', false);
            $(elemButton).removeClass('is-disabled');
        } else {
            $(elemButton).prop('disabled', 'disabled');
            $(elemButton).addClass('is-disabled');
        }
    }

    if ($(elemDate).val().length === 5) {
        if (
            (!$(elemDate).hasClass('invalid') || $(elemDate).val().length > 4
            ) && (
                $(elemCardNumber).val().length > 16 &&
                !$(elemCardNumber).hasClass('invalid') &&
                $(elemName).val().length >= 2 &&
                $(elemCvv).val().length > 2
            )) {
            $(elemButton).prop('disabled', false);
            $(elemButton).removeClass('is-disabled');
        } else {
            $(elemButton).prop('disabled', 'disabled');
            $(elemButton).addClass('is-disabled');
        }
    }

    if ($(elemCvv).val().length > 2) {
        if (
            (!$(elemDate).hasClass('invalid') || $(elemDate).val().length > 4) && (
                $(elemCardNumber).val().length > 16 &&
                !$(elemCardNumber).hasClass('invalid') &&
                $(elemName).val().length >= 2 &&
                $(elemDate).val().length === 5 &&
                !$(elemDate).hasClass('invalid')
            )) {
            $(elemButton).prop('disabled', false);
            $(elemButton).removeClass('is-disabled');
        } else {
            $(elemButton).prop('disabled', 'disabled');
            $(elemButton).addClass('is-disabled');
        }
    }
}
/**
 * function used to fix label issue
 */
function updatingPlaceHolderText() {
    $('input[type=text], input[type=password], input[type=email], input[type=url], input[type=tel], input[type=number], input[type=search], input[type=date], input[type=time], textarea').each(function (element, i) {

        if (($(this).val() !== undefined && $(this).val().length > 0) || ($(this).attr('placeholder') !== null && $(this).attr('placeholder') !== undefined)) {
            $(this).siblings('label').addClass('active');
        } else {
            $(this).siblings('label').removeClass('active');
        }
    });
}

/**
 * function verified if none of the shipping object is selected disable proceed to checkout
 */
function updateProceedToPayment() {
    if ($('.shipping-page-details').length > 0 && $('.shipping-page-details .delivery-box.checked').length === 0) {
        $('.js-proceed-payment').addClass('is-disabled');
    }
}

/**
 * used to populate address according to selected address in the form
 * @param {*} selector selected address objectr
 */
function populatingAddressForm(selector) {
    var $activeForm = $('.addressTabContent.active .js-add-address-form');
    var address = selector.parent().attr('data-address');

    if (address === null || address === 'null') {
        // clear form
        var selectedOption = $activeForm.find('#cityShipment option:first').text();
        $activeForm.find('#suite').val('').siblings('label').removeClass('active');
        $activeForm.find('#address2').val('').siblings('label').removeClass('active');
        $activeForm.find('#address1').val('').siblings('label').removeClass('active');
        $activeForm.find('#cityShipment option:eq(0)').prop('selected', true);
        $activeForm.find('#cityShipment option:first').attr('selected', 'selected');
        $activeForm.find('#cityShipment').parent('.custom-select').find('span').text(selectedOption);
        // $activeForm.find('#cityShipment').parents('.custom-select span:eq(0)').text(selectedOption);
        $activeForm.find('.mobile-button,button.global-btn').addClass('is-disabled');
    } else {
        if (typeof address === 'string') {
            address = JSON.parse(address);
        }

        $activeForm.find('#suite').val(address.suite);
        $activeForm.find('#address2').val(address.address2);
        $activeForm.find('#address1').val(address.address1);
        $activeForm.find('#cityShipment option[value="' + address.city + '"]').prop('selected', true);
        updatingPlaceHolderText();
        $activeForm.find('.mobile-button,button.global-btn').addClass('is-disabled');
    }
}

/**
 * Go to forgot password
 * @constructor
 */
function addressTab() {
    var $this = $(this);
    var $tab = $this.data('type');
    $this.parent().addClass('active').siblings().removeClass('active');
    $('.addressTabContent.' + $tab + '-tab').addClass('active').siblings().removeClass('active');
    populatingAddressForm($this);
}

$(document).on('click', '.js-add-new-address', function () {
    var $this = $(this);
    var activeButtton = helper.getActivePaymentButton();
    $('.js-proceed-payment').addClass('is-disabled');
    $(activeButtton).addClass('is-disabled');
    $('.add-new-address-panel').addClass('show visible');
    // logic to select the first address as active tab
    if ($('.add-new-address-panel .addressTabs').find('li.active').length > 0) {
        $('.add-new-address-panel .addressTabs').find('li.active a').trigger('click');
    } else {
        $('.add-new-address-panel .addressTabs').find('li:first a').trigger('click');
    }
});


$(document).on('click', '.js-cancel-address', function () {
    $('.add-new-address-panel').removeClass('show visible');
    $('.addressTabContent').find('.mobile-button,button.global-btn').addClass('is-disabled');
});



$('body').on('click', '.addressTabs a', addressTab);


$(document).on('submit', '.js-add-address-form', function (e) {
    e.preventDefault();

    var $form = $(this);
    var isUpdate = $form.attr('data-update');
    var addressID = $form.find('#addressId').val();
    var url = $form.attr('action');
    $form.find('.mobile-button').addClass('is-disabled');
    if (isUpdate === true || isUpdate === 'true') {
        url += '?addressId=' + addressID;
    }
    $.ajax({
        url: url,
        type: 'POST',
        data: $form.serialize(),
        success: function (data) {
            if (!data.success) {
                toastr(data, data.error);
            } else {
                $(document).trigger('addAddress:success', data);
                helper.updateAddressOnStorefront(data);

                if (!('cartPage' in data) && !data.cartPage) {
                    isMobile ? alert(data.message) : toastr(data, data.message);
                }

                if ('order' in data) {
                    $('.cart-page .grand-total').html($dotLoaderGray);
                    helper.updateCartTotals(data.order);
                }

                $('body').attr('data', JSON.stringify(data));
                if (!isMobile) {
                    if ($('.user-payment-instruments').hasClass('checkout-hidden')) {
                        $(document).trigger('desktop:newCardValidation', {
                            order: 'asif',
                            customer: 'customer'
                        });
                    } else {
                        $(document).trigger('desktop:saveCardValidation');
                    }
                } else {
                    var activeButtton = helper.getActivePaymentButton();
                    $(activeButtton).removeClass('is-disabled');
                    $('.additonal-buttons-cart.shipping-level-button a').removeClass('is-disabled');
                }
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.errorMessage);
            }
        }
    });
});

$(document).off('click', '.js-delivery-box:not(".checked")').on('click', '.js-delivery-box:not(".checked")', function (e) {
    var $this = $(this);
    $this.siblings().removeClass('checked');
    $this.addClass('checked');
    var $flag = false;

    var donate = $this.data('donate');
    var donateURL = $this.data('donate-url');
    var id = $this.find('.js-radio-selector').data('id');
    if (isMobile) {
        var activeButtton = helper.getActivePaymentButton();
        $(activeButtton).addClass('is-disabled');
        $('.additonal-buttons-cart.shipping-level-button a').addClass('is-disabled');
    }

    // get all non donetable products
    var pids = [];
    if (isMobile) {
        var $cartItems = $('.cart-items.cart-section-item[data-donatebale="false"]');
        if ($cartItems.length > 0) {
            $cartItems.each(function () {
                pids.push($(this).attr('data-product-id'));
            });
        }
    } else {
        var $cartItemsDsk = $('.product-info.lineItemhover.product-detail[data-donatebale="false"]');
        if ($cartItemsDsk.length > 0) {
            $cartItemsDsk.each(function () {
                pids.push($(this).attr('data-pid'));
            });
        }

        $('#saved-payment-security-code, #securityCode').val('');
    }

    $.spinner().start();
    if (typeof id !== 'undefined' && id.indexOf('aramex') > -1) {
        e.preventDefault();
        // save shipment information if user chosses standard delivery
        var uuid = $(this).find('.newAddress a.active').attr('data-uuid');
        $('.semi-open').removeClass('semi-open');
        $('.add-new-address-panel').removeClass('semiOpen');
        var formData = new FormData();

        if (uuid && typeof uuid !== 'undefined' && uuid !== 'null') {
            $('.js-proceed-payment').html('');
            $('.js-proceed-payment').append($dotLoader);
            $('.cart-page .grand-total').html($dotLoaderGray);
            $('.js-proceed-payment').addClass('is-disabled');
            $('.delivery-box').addClass('is-disabled');
            formData.append('addressUUID', uuid);
            formData.append('methodID', id);
            $.ajax({
                url: $(this).data('shipmenturl'),
                type: 'POST',
                data: formData,
                processData: false,
                contentType: false,
                success: function (data) {
                    if (!data.error) {
                        // updateCartTotals(data.order);
                    }
                    $('.dotspinner').remove();
                    $('.js-proceed-payment').html($btnText);
                    $.ajax({
                        url: donateURL,
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            donated: false,
                            type: 'shipping',
                            pids: JSON.stringify(pids)
                        },
                        success: function (response) {
                            $.spinner().stop();
                            var cartsummary = $('.cart-summary');
                            helper.updateCartTotals(response);
                            helper.updateShippingSippingStatus(response);
                            helper.handleFreeTicketIcons(false);
                            helper.handleTicketQuantity(cartsummary, response);
                            $('.dotspinner').remove();
                            $('.delivery-box').removeClass('is-disabled');
                            $('.js-proceed-payment').html($btnText);
                            if (!isMobile) {
                                helper.handleDesktopAllValidations(data);
                                resetPaymentOptions(data);

                            }
                            $('body').trigger('GTM:selectShipping');
                        },
                        error: function () {
                            $('.dotspinner').remove();
                            $('.delivery-box').removeClass('is-disabled');
                            $('.js-proceed-payment').html($btnText);
                        }
                    });
                },
                error: function () {
                    $('.dotspinner').remove();
                    $('.js-proceed-payment').html($btnText);
                    $('.delivery-box').removeClass('is-disabled');
                }
            });
        } else {
            formData.append('methodID', id);
            $('.addressTabContent.active').find('.form-control').each(function () {
                var $input = $(this);
                if ($input.val() === '') {
                    $flag = true;
                    return false;
                }
            });

            if ($('.addressTabContent.active #cityShipment').val === '') {
                $flag = true;
                return false;
            }

            $.ajax({
                url: $(this).data('shipmenturl'),
                type: 'POST',
                data: formData,
                processData: false,
                contentType: false,
                success: function () {
                    $.ajax({
                        url: donateURL,
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            donated: false,
                            type: 'shipping',
                            pids: JSON.stringify(pids)
                        },
                        success: function (response) {
                            $.spinner().stop();
                            var cartsummary = $('.cart-summary');
                            helper.updateCartTotals(response);
                            helper.updateShippingSippingStatus(response);
                            helper.handleFreeTicketIcons(false);
                            helper.handleTicketQuantity(cartsummary, response);
                            $('.dotspinner').remove();
                            $('.js-proceed-payment').html($btnText);
                            if (!isMobile) {
                                helper.handleDesktopAllValidations(response);
                                resetPaymentOptions(response);
                            }
                            $('body').trigger('GTM:selectShipping');
                        },
                        error: function () {
                            $('.dotspinner').remove();
                            $('.js-proceed-payment').html($btnText);
                        }
                    });
                }
            });
        }
    } else {
        $('.cart-page .grand-total').html($dotLoaderGray);
        $('.delivery-box').addClass('is-disabled');
        $('.semi-open').removeClass('semi-open');
        $('.add-new-address-panel').addClass('semiOpen');

        // logic to remove shipment
        $.ajax({
            url: $(this).data('resetshipment'),
            type: 'GET',
            success: function () {
                $.ajax({
                    url: donateURL,
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        donated: false,
                        type: 'pickup',
                        pids: JSON.stringify(pids)
                    },
                    success: function (data) {
                        helper.updateCartTotals(data);
                        var cartsummary = $('.cart-summary');
                        helper.updateShippingSippingStatus(data);
                        helper.handleFreeTicketIcons(donate);
                        helper.handleTicketQuantity(cartsummary, data);
                        $('.delivery-box').removeClass('is-disabled');
                        if (!isMobile) {
                            helper.handleDesktopAllValidations(data);
                            resetPaymentOptions(data);
                        }
                        $.spinner().stop();
                    },
                    error: function () {
                        $('.dotspinner').remove();
                        $('.js-proceed-payment').html($btnText);
                        $('.delivery-box').removeClass('is-disabled');
                    }
                });
            }, error: function () {
                $('.dotspinner').remove();
                $('.js-proceed-payment').html($btnText);
                $('.delivery-box').addClass('is-disabled');
            }
        });
    }
});

$('.js-new-donate-check').on('click', function (e) {
    e.preventDefault();
    var $this = $(this);
    var donate;
    var donateURL = $this.data('donate-url');
    var $input = $this.find('input');
    $input.prop('checked', !$input.prop('checked'));
    // $this.addClass('checked');
    $.spinner().start();
    if ($input.is(':checked')) {
        donate = true;
    } else {
        donate = false;
    }
    $('#js-shipping-area-module').find('.delivery-box').removeClass('checked');
    $.ajax({
        url: $(this).data('resetshipment'),
        type: 'GET',
        success: function () {
            var products = [];
            // fetch all donated product and change it's status
            var $parentContainer = $this.parents('.cartNewContainer');
            if ($parentContainer.length > 0) {
                if (isMobile) {
                    $parentContainer.find('.cart-section-item').each(function () {
                        var pid = $(this).attr('data-product-id');
                        if (pid !== '') {
                            products.push(pid);
                        }
                    });
                } else {
                    $parentContainer.find('.card.product-info.product-detail').each(function () {
                        var pid = $(this).attr('data-pid');
                        if (pid !== '') {
                            products.push(pid);
                        }
                    });
                }
            }
            $.ajax({
                url: donateURL,
                type: 'POST',
                dataType: 'json',
                data: {
                    donated: donate,
                    type: donate ? 'donated' : 'shipping',
                    pids: JSON.stringify(products)
                },
                success: function (data) {
                    helper.updateCartTotals(data);
                    var cartsummary = $('.cart-summary');
                    helper.handleFreeTicketIcons(donate);
                    helper.handleTicketQuantity(cartsummary, data);

                    helper.updateShippingSippingStatus(data);
                    helper.updateSizeSelectionForCart(data);
                    $('.dotspinner').remove();

                    // update gtm data
                    var gtagElems = $('span[data-gtag-event="begin_checkout"]');
                    if (data && 'gtags' in data) {
                        gtagElems.attr('data-gtag-param', data.gtags[0].param);
                    }
                    $('body').trigger('GTM:Donate', {
                        donate: donate
                    });
                    $('.js-donated-state').text(donate ? resources.donatedText : resources.notDonatedText);
                    if (!isMobile) {
                        helper.handleDesktopAllValidations(data);
                        resetPaymentOptions(data);
                    } else {
                        helper.minimumOrder(data);
                    }
                    $.spinner().stop();
                },
                error: function () {
                    $('.dotspinner').remove();
                    $.spinner().stop();
                }
            });
        }, error: function () {
            $('.dotspinner').remove();
            $.spinner().stop();
        }
    });
});

$(document).on('click', '.delivery-box .address-btn:not(".nonRegistered")', function (e) {
    e.stopImmediatePropagation();
    e.preventDefault();
    if ($(this).hasClass('js-add-new-address')) {
        return false;
    }

    if ($(this).hasClass('active')) {
        return false;
    }
    var $this = $(this);
    var url = $this.parents('.delivery-box').data('shipmenturl');
    var uuid = $this.data('uuid');
    var formData = new FormData();
    var $address = $this.attr('data-address');
    var $text = $this.attr('data-addressid');
    if ($this.attr('data-locale') === 'ar') {
        $text = $this.attr('data-addressalias');
    }
    formData.append('addressUUID', uuid);
    // $.spinner().start();
    $this.addClass('active').siblings().removeClass('active');
    // $this.siblings('.address-btn:not(".js-add-new-address")').each(function () {
    //     var dataId = $(this).attr('data-addressid');
    //     if ($(this).attr('data-locale') === 'ar') {
    //         dataId = $(this).attr('data-addressalias');
    //     }
    //     $(this).text(dataId);
    // });
    // $this.text(resources.deliverLabel + ' ' + $text);
    $('.changeOfAddress').html($address);
    $('.cart-page .grand-total').html($dotLoaderGray);
    $('.delivery-box').addClass('is-disabled');
    $('.js-proceed-payment').html('');
    $('.js-proceed-payment').append($dotLoader).addClass('is-disabled');
    $('.add-new-address-panel').removeClass('show visible');
    $.ajax({
        url: url,
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        success: function (data) {
            // $.spinner().stop();
            if (!data.error) {
                $('.dotspinner').remove();
                helper.updateCartTotals(data.order);
                $('.js-proceed-payment').html($btnText);
                // if (!$('.credit-card-form').hasClass('checkout-hidden')) {
                //     buttonPaymentEnable('#dwfrm_cart', '#dwfrm_cart #cardNumber', '#dwfrm_cart #cardOwner', '#dwfrm_cart #expiry-date', '#dwfrm_cart #securityCode', '#submitPaymentCartBtn, .additonal-buttons-cart.shipping-level-button a');
                // } else if ($('.selected-payment')) {
                //     var $cvc = $('.selected-payment').find('.saved-payment-security-code').val();
                //     if ($cvc.length > 2) {
                //         var activeButtton = helper.getActivePaymentButton();
                //         $(activeButtton).removeClass('is-disabled');
                //         $(activeButtton).removeAttr('disabled');
                //         $('.additonal-buttons-cart.shipping-level-button a').removeClass('is-disabled');
                //         $('.additonal-buttons-cart.shipping-level-button a').removeAttr('disabled');
                //     }
                // }
            }
            $('.delivery-box').removeClass('is-disabled');
        }
    });
});

// cvv validation 
$(document).on('keyup', '.selected-payment .saved-payment-security-code', function () {
    $(document).trigger('validated:payment');
});

$(document).on('addAddress:success', function () {
    $('.js-cancel-address').trigger('click');
    $('.add-new-address-panel').removeClass('show');
    $('.address-btn.active:not(.js-add-new-address)').text($('.address-btn.active:not(.js-add-new-address)').data('addressalias'));
});

$(document).ready(function () {
    updatingPlaceHolderText();
    $('.addressTabs li.active a').trigger('click');
    updateProceedToPayment();
});
