'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./cart/checkout'));
    processInclude(require('./cart/donate'));
    processInclude(require('./cart/shipping'));
    processInclude(require('./cart/cardValidation'));
    processInclude(require('./cart/creditWallet'));
    processInclude(require('./login/shippingValidations'));
    processInclude(require('./components/notifications'));
});
