'use_strict';

const helper = require('./helper');

module.exports = function () {
    $(document).on('change', '#wallet-box input:checkbox', function (e) {
        e.preventDefault();
        // logic to apply wallet balance
        var isWalletBalApplied = $(this).is(':checked');
        var url = null;
        var $parent = $(this).parents('#wallet-box');
        if (isWalletBalApplied) {
            url = $parent.data('add-credit');
        } else {
            url = $parent.data('remove-credit');
        }
        if (url !== null) {
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'POST',
                success: function (response) {
                    $.spinner().stop();
                    $('body').attr('data', JSON.stringify(response.order));
                    if (response.isFullyPaidWithCredits) {
                        helper.handleFullCreditPayment(response.order);
                    } else {
                        helper.handlePartialCreditPayment(response.order);
                    }
                }
            })
        } else {
            alert('error applying wallet balance');
        }
    });
};
